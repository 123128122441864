.OAS-owner_div_container {
  background-color: var(--Blue_6);
  margin-top: 60px;
  height: 110px;
  width: 100%;
  z-index: 998;
}

.OAS-owner_div_container-popup {
  background-color: var(--Blue_6);
  margin-top: 16px;
  height: 110px;
  width: 100%;
  z-index: 998;
}

.OAS-owner_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 115px;
  z-index: 1;
  position: relative;
  align-items: center;
}

.OAS-owner_div_height {
  transition: height 0.2s ease-in-out;
  height: 170px;
}

.OAS-owner_search_div {
  width: 50px;
  height: 30px;
  background-color: var(--Primary_white);
  border-radius: 5px;
  padding: 8px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.OAS-filter-containers {
  height: 30px !important;
  width: 50px !important;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.OAS-owner_search_div[data-lable="search"]:hover {
  background-color: var(--Blue_7) !important;
}

.OAS-filter-images {
  width: 14px !important;
  height: 14px;
}

.OAS-owner-viewall-page-select-div {
  height: 36px !important;
  width: 131px !important;
  cursor: pointer;
  display: flex;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.OAS-pagination-containers {
  height: 36px !important;
  width: 136px !important;
  cursor: pointer;
  display: flex;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.OAS-filter-transition-close {
  transition: all 0.2s ease-in;
  height: 0;
  visibility: hidden;
}

.datacontent {
  overflow-y: scroll;
  height: calc(100% - 100px);
}

.OAS-filter-transition-open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  top: 90;
  position: absolute;
  background-color: var(--Blue_6);
  /* height: auto; */
  height: 261px;
  border-radius: 0 !important;
  width: 100%;
  z-index: 998;
  margin-top: -5px;
}

.OAS-beneTxnFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 165px;
  padding-right: 30px;
}

.OAS-beneTxnFilterSection3 {
  display: flex;
  flex-direction: column;
  height: 225px;
  padding-right: 30px;
}

.OAS-bene_Txn_Calendar_main_div {
  border-radius: 10px;
  /* margin-left: 15px; */
}

.OAS-beneTxn_date_heading_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-beneTxn_date_heading_left {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.OAS-bene_txn_viewall_calendar_icon {
  height: 17px;
  align-items: center;
  justify-content: center;
  width: 20px !important;
}

.OAS-beneTxn_date_heading_right {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.OAS-beneTxn_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-filter_applied_div {
  height: 60px;
  background-color: var(--Blue_6);

  /* margin-top: 200px; */
}

.OAS-beneTxnviewallsidebar.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.OAS-beneTxnviewallsidebar.open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transition: height 0.2s ease-in;
  transform-origin: top center;
  top: 90;
  /* height: auto; */
  height: 170px;
}

.OAS-beneTxnviewallsidebar {
  background-color: var(--Blue_6);
  margin-top: 221px;
  height: 0px;
  border-radius: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: height 0.2s ease-in;
  visibility: hidden;
}

/* Additional Details  */

.OAS-beneTxnviewallsidebar.additionalDetailsclosed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.OAS-beneTxnviewallsidebar.addtionalDetailsopen {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transition: height 0.2s ease-in;
  transform-origin: top center;
  top: 90;
  /* height: auto; */
  height: 301px;
}

/* Additional Details  */

.OAS-beneTxnviewallFilterDiv {
  /* display: flex; */
  /* justify-content: space-between; */
  /* width: 100%; */
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 10px;
  z-index: 1;
  flex-direction: row;
}

.OAS-beneTxnFilterbuttonContainer {
  display: flex;
  justify-content: end;
  padding: 20px 0;
  align-items: end;
}

.OAS-table-div-container {
  position: relative;
  /* margin-top: 25px;     */
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  justify-content: space-evenly;
  background-color: var(--Blue_7);
  width: 100%;
  height: 50px;
  z-index: 1;
  overflow-y: hidden;
}

.OAS-table-div-content {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  justify-content: space-evenly;
  width: 100%;
  min-height: 51px;
  height: auto;
  z-index: 1;
  overflow-y: hidden;
  position: relative;
}

.OAS-table-div-content::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 20px;
  right: 20px;
  border-bottom: 1px solid var(--Blue_7);
}

.OAS-apply {
  margin-left: 15px;
  width: 87px;
  height: 25px;
  border-radius: 8px;
  padding: 4px 27px;
  opacity: 1;
}

.OAS-table-div-content:last-child,
.OAS-table-div-content:last-child::after {
  border-bottom: unset;
  margin-bottom: 10px;
}

.statement_no_found {
  width: 320px;
  height: 226px;
  margin-bottom: 20px;
}

.oas-selectaccount {
  height: 36px;
  width: 200px;
  background-color: var(--Blue_8);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.oas-selectaccount:hover {
  background: var(--Blue_8_hover);
}

.oas-selectaccounttext {
  color: var(--Primary_white);
  font: normal normal 400 12px/16px Open Sans;
}

.OAS-border-mid-div {
  width: 1px;
  border-right: 1px solid var(--Blue_3);
}

.OAS-label-bank-width {
  min-width: 130px !important;
}
.OAS-label-account-width {
  min-width: 140px !important;
}
.ownerredirect {
  color: var(--Pending) !important;
  text-decoration: underline;
  cursor: pointer;
}
/* .label-TimeDate-width{width: 130px !important;} */

/* .label-ValueDt-width{width: 75px !important;} */

.OAS-label-div-trans {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 167px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-upi {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 210px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-clamt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 165px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-val {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 90px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-narr {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  width: 280px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  word-break: break-all;
  text-wrap: wrap;
}

.OAS-label-div-ref {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  width: 148px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  word-break: break-all;
  text-wrap: wrap;
}

.OAS-label-div-type {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 80px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
}

.OAS-label-div-type-width {
  min-width: max-content;
}

.OAS-label-div-amt {
  font: normal normal normal 12px/16px Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--Primary_white);
  min-width: 165px;
  min-height: 50px;
  height: auto;
  padding: 5px 10px;
  text-align: center;
  font-weight: 400;
}

.balance_type {
  display: block;
  width: 84px;
  height: 50px;
}

.type-main-btn {
  margin: 0;
  width: 77px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: var(--Blue_7);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.OAS-owner-border-div-line {
  height: 131px;
  border-left: 1px solid var(--Blue_3);
  margin: auto 15px auto 0px;
  /* margin-top: 40px; */
  /* margin-right: 15px; */
  /* margin-left: 5px !important; */
}

.OAS-owner_filterdropdown {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1024px !important;
  height: auto;
  border-top: none;
  margin: 0 auto;
  z-index: 1;
}

.OAS-owner-main-btn {
  margin: 0 !important;
  width: 56px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-transform: none !important;
  position: absolute !important;
  z-index: 1 !important;
  background-color: var(--Blue_8) !important;
  display: flex !important;
  justify-content: space-evenly;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.OAS-owner_select_scroll_com {
  height: 24px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 4px 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
  padding: 2px 4px;
}

.OAS-owner_select_scroll_com:hover {
  background-color: var(--Blue_7) !important;
}

/* .beneTxnviewallFilterDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1024px !important;
    margin: 0 auto;
    padding-top: 20px;
    z-index: 1;
    flex-direction: row;
} */

.beneTxnFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 165px;
  /* padding-right: 30px; */
}

.OAS-statement-type {
  display: block;
  width: 80px;
  height: 36px;
}

.OAS-download_statement {
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  font-weight: 600;
  cursor: pointer;
}
/* 
.statement_text {
    text-align: left;
    font: normal normal normal 10px/14px Open Sans;
    color: var(--Primary_grey);
    padding: 2px 0px 0px 0px;
}

.statement_header {
    font: normal normal 600 16px/22px Open Sans;
    color: var(--Primary_white);
    text-align: left;
    padding-bottom: 2px;
} */

.OAS-pdf-main-btn {
  padding: 5px 10px !important;
  margin: 0 1px !important;
  width: 80px !important;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: var(--Failed) !important;
  display: flex !important;
  justify-content: space-between;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

/* .bank_high_to_low_select_select{} */
.OAS-bank_high_to_low_select_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 39px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.range_background {
  background-color: var(--Blue_8) !important;
}

.range_background:hover {
  background-color: var(--Blue_8_hover) !important;
}

/* .bank_high_to_low_select_scroll{} */
.OAS-bank_high_to_low_select_scroll {
  position: absolute;
  top: 37.5px;
}

/* .status_icon-img{} */

.OAS-statement_select_scroll_com {
  padding: 6px 4px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px / 15px Open Sans !important;
  letter-spacing: 0px !important;
  margin: 8px 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
}

.OAS-statement_select_scroll_com:active {
  background-color: #6d3034;
}

.OAS-statement_select_scroll_com:hover {
  background-color: #fc7a82 !important;
}

.statement_calendar_date_select_left {
  height: 27px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Failed);
  padding-left: 20px;
}

.OAS-owner_account_number_text_input {
  background: var(--Blue_8);
  border-radius: 10px;
  opacity: 1;
  width: 216px;
  height: 37px;
  padding: 15px;
  font: normal normal normal 12px/17px Open Sans;
  color: var(--Primary_white);
}

.OAS-owner_account_number_text_input::placeholder {
  color: var(--Primary_grey);
}

.OAS-owner_account_number_text {
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  padding-bottom: 5px;
}

.OAS-amtDiv_type {
  width: 216px;
  background-color: var(--Blue_8);
  border-radius: 15px;
  height: 37px !important;
}

.OAS-amount-main-btn {
  margin: 0;
  width: 216px;
  border-radius: 10px;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-transform: none;
  position: absolute;
  z-index: 1;
  background-color: var(--Blue_8);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.2s ease;
}

.OAS-amount-main-btn:hover{
  background-color: var(--Blue_8_hover) !important;
}

.OAS-amount-inputs-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  color: var(--Primary_grey);
  padding: 45px 10px 10px;
  width: 216px;
  /* padding-top: 55px; */
  /* padding-left: 8px; */
}

.OAS-input-container label {
  display: inline-block;
  font-size: 10px;
  margin-bottom: 3px;
  text-align: left;
}

.OAS-amount-inputs-div p {
  font-size: 10px;
  margin-bottom: 10px;
  text-align: left;
  line-height: 1.1;
}

.OAS-input-container {
  display: inline-block;
}

.OAS-input-container input {
  padding-left: 20px;
}

.OAS-inputs-div-btns button:nth-child(1) {
  width: 100%;
  height: 24px;
  top: 218px;
  left: 10px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  border: 1px solid var(--F9F9F9---Primary-white, var(--Primary_white));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.OAS-inputs-div-btns button:nth-child(2) {
  width: 100%;
  height: 24px;
  top: 218px;
  left: 77px;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 8px;
  background: var(--Failed);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.OAS-amount-inputs-div input {
  background-color: var(--Blue_4);
  width: 195px;
  height: 24px;
  top: 78px;
  left: 10px;
  gap: 0px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.OAS-acc-viewall-clear-filter {
  border: 0.5px solid var(--Primary_white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OAS-acc-viewall-apply-filter {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.OAS-buttoncontainer {
  margin-right: 0;
  display: flex;
  padding-left: 20px;
}

.OAS_grey {
  color: var(--Primary_grey);
}

.OAS-buttoncontainer-text {
  width: 28px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.select_text {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}

.OAS-clear-button {
  cursor: pointer;
}

.OAS-clear-button:hover {
  background-color: var(--Blue_6_hover);
}

.OAS-clear-button.disabled {
  pointer-events: none;
  cursor: not-allowed;
  background-color: var(--Blue_7);
  opacity: 0.6;
}

.OAS-clear-button.disabled:hover {
  background-color: inherit;
}

.OAS-apply-button {
  background-color: var(--Failed);
}

.OAS-apply-button:hover {
  opacity: 0.9;
}

.OAS-apply-button.disabled {
  background-color: var(--Secondary_grey) !important;
  pointer-events: none;
  cursor: not-allowed;
}

.OAS-apply-button.disabled:hover {
  background-color: inherit;
}

.OAS-inputs-div-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 8px auto;
}

.OAS-filter_badge {
  width: auto;
  max-width: 230px;
  height: 36px;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  border: 2px solid var(--Blue_8);
  border-radius: 48px;
  display: flex;
  gap: 10px;
  padding: 7px 10px;
}

.bene_txn_viewall_pagination_input {
  height: 36px;
}

.statementviewallscroll::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(200, 200, 200) !important;
  opacity: 0.6 !important;
}

@media (min-width: 2000px) {
  .popupstatementviewallscroll {
    margin-bottom: 2% !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
  .popupstatementviewallscroll {
    margin-bottom: 9% !important;
  }
}
