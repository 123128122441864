.beneTxnConfirm_Main {
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.divideTxnconfirm {
  width: 472px;
  margin: 0px 20px;
}

.confirmright {
  width: 100%;
}

.confirmleft {
  width: 100%;
}

.bene_detail {
  background-color: #29487e;
  border-radius: 20px;
}

.bene_inside {
  background-color: #3a5686;
  width: 100%;
  border-radius: 20px;
  /* padding-left: 30px;
  padding-top: 20px; */
  padding: 20px;
}
.bene_inside p {
  width: auto;
  /* height: 44px; */
  text-align: left;
  font: normal normal 600 16px / 22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}
.bene_name {
  /* padding: 0.5rem; */
  min-height: 3rem;
  margin-top: 1rem;
  width: 100%;
  /* height: 53px; */
  background-color: #2a487e;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  color: #ff7f33;
}

.ass_num {
  padding-top: 10px;
  color: var(--Primary_white);
}

.ass_name {
  color: #d5d5d5;
}

.ifsc_num {
  color: #fafafa;
  margin-top: 10px;
}

.ifsc_name {
  color: #d5d5d5;
  font-size: 12px;
}

.select_cat {
  background-color: #29487e;
  border-radius: 20px;
  height: 150px;
  padding: 20px;
  /* padding-left: 30px;
  padding-top: 20px; */
}

.select_cat_p {
  width: 177px;
  height: 44px;
  text-align: left;
  font: normal normal 600 16px / 22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}
.selected_cat_show {
  width: inherit;
  height: 60px;
  background-color: #3a5686;
  border-radius: 10px;
  display: flex;
}

.selected_cat_show img {
  background-color: white;
  width: 59px;
  height: 59px;
  border-radius: 10px;
  padding: 8px;
}

.selected_cat_show p {
  color: white;
  padding-top: 6px;
  padding-left: 15px;
}

.settle_method {
  padding: 20px;
  border-radius: 20px;
}
.settle_method_p {
  width: auto;
  height: 50px;
  text-align: left;
  font: normal normal 600 16px / 22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.selected_set_show {
  display: flex;
  margin-bottom: 20px;
}

.accNumber p {
  color: white;
}
.accNumber span {
  color: #dadada;
}

.ifsc p {
  color: white;
}

.ifsc span {
  color: #dadada;
}

.balance p {
  color: #4de659;
}
.amount-color {
  color: #4de659 !important;
}
.balance span {
  color: #dadada;
}

.division {
  width: 100%;
  display: flex;
  justify-content: normal;
}

.payment_method p {
  color: white;
}

.payment_method span {
  color: #dadada;
}
.amount {
  width: 100%;
  height: 150px;
  background-color: #338a3e;
  display: flex;
  justify-content: center;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  font-size: 32px;
  color: #ffffff;
}

.additional_detail span {
  width: 177px;
  height: 22px;
  text-align: left;
  font: normal normal 400 16px / 22px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
}

.field {
  width: 412px;
  height: 40px;
  background-color: #667ca3;
  border-radius: 10px;
  color: white;
  padding-left: 20px;
  padding-top: 9px;
}

.main_details_upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_details_upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.right_side_select_ownerBene_div_top_resConfirm {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #3a5686;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .beneTxnConfirm_Main {
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
  .divideTxnconfirm {
    padding: 20px 20px 0px 20px;

    margin: 0 !important;
  }
  .divideconfirm {
    padding: 0px 20px !important;
    margin: 0 !important;
  }
  .bene_name {
    width: inherit !important;
  }

  .bene_inside {
    border-radius: 10px;
  }
  .bene_detail {
    border-radius: 10px;
  }

  .settle_method {
    border-radius: 10px;
  }

  .select_cat {
    border-radius: 10px;
  }
  .right_side_select_ownerBene_div_top_resConfirm {
    border-radius: 10px;
  }
}
.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.25rem;
  border-radius: 10px;
}

.amount-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 32px;
  position: relative;
  font-weight: 600;
  width: 100%;
  color: #f9f9f9;
}

.amount-sym {
  position: absolute;
  left: 22px;
  padding-left: 10px;
}

.amount-number {
  display: inline-block;
  text-align: center;
  flex-grow: 1;
  white-space: nowrap;
}
.confirmleft {
  text-align: left;
}
