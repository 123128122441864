.internal_account_div_container {
    background-color: var(--Blue_6);
    position: fixed;
    padding-top: 105px;
    z-index: 999;
    height: 177px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.internal_account_div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    align-items: center;
    height: 59px;
    max-width: 1024px;
}

.internal-account-cta-btn {
    border: none;
    background-color: var(--Blue_8);
    width: 200px;
    height: 36px;
    border-radius: 10px;
    margin-top: 10px;
}

.internal-account-cta-btn-text {
    width: 98px;
    height: 16px;
    font-weight: 400;
    font-size: 12px;
    color: var(--Primary_white);
}