.popover-main {
  width: 100%;
  height: 0;
  background: #e9eff7;
  margin: auto;
  padding-top: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  transform-origin: top center;
  overflow: hidden;
  opacity: 0;
  transition: height 300ms ease-in, opacity 500ms ease-in;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.popover-main.open {
  height: 225px;
  padding-top: 65px;
  transition: height 500ms ease-in;
  opacity: 1;
}

.popover-main.close {
  height: 0px;
  transform-origin: top center;
  opacity: 0;
  animation: collapseUp 500ms ease-in forwards;
}

@keyframes collapseUp {
  0% {
    opacity: 1;
    height: 216px;
  }
  80% {
    opacity: 0.5;
    height: 50px;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}

.popover-content {
  max-width: 1024px;
  margin: auto;
  position: relative;
  height: 100%;
  flex-grow: 1;
  box-shadow: none;
  border-top: 2px solid var(--Primary_grey);
  overflow: hidden;
  padding: 20px 0 20px 0;
}

.sections-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cards-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.section {
  flex: 1;
  text-align: center;
}

.section-title {
  font-size: var(--Font_16);
  font-family: "Open Sans";
  font-weight: var(--Font_semibold);
  color: var(--Blue_6);
  padding-bottom: 5px;
  text-transform: uppercase;
}

.menu-items {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.menu-item {
  text-align: center;
  min-width: fit-content;
}

.corporate-items {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.menu-item-link {
  text-decoration: none;
  white-space: nowrap;
}

.info-text {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  font-size: var(--Font_10);
  font-family: "Open Sans";
  font-weight: var(--Font_normal);
  color: var(--Secondary_grey);
  z-index: 10;
}

@media only screen and (max-width: 1024px) {
  .popover-content {
    max-width: 95%;
  }

  .sections-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .menu-items {
    flex-wrap: wrap;
    justify-content: center;
  }

  .corporate-items {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .popover-main {
    display: none;
  }
}
.popover-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
  padding: 10px;
  transition: opacity 0.2s ease-in;
}

.popover-main.close .popover-footer {
  opacity: 0;
  pointer-events: none;
}
.popover-main.close .popover-content {
  border-top: none;
  opacity: 0;
  pointer-events: none;
}
