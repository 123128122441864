.owner_sort_by_acc_balance>p {
  width: 134px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  opacity: 1;
  margin-bottom: 5px;
}

.bank_high_to_low_select {
  padding: 0 !important;
  margin: 0 !important;
  text-transform: none !important;
  position: absolute !important;
  z-index: 999999 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.bank_high_to_low_select:hover {
  background-color: var(--Blue_8_hover)!important;
}

.bank_high_to_low_select_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 39px;

  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.bank_high_to_low_select_select>span {
  width: fit-content;
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  transition: all 0.2s ease;
  font: normal normal normal 14px/19px Open Sans;
}

.bank_high_to_low_select_scroll_com {
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  margin: 3px 0px !important;
}

.bank_high_to_low_select_scroll_com:hover {
  background-color: #506994 !important;
}

.bank_high_to_low_select_select>img {
  width: 11px;
  height: 7px;
  opacity: 1;
  transition: all 0.2s ease;
}

.bank_high_to_low_select_scroll_line {
  height: 1px;
  position: absolute;
  background-color: #3a5686;
  top: 39px;
  left: 10px;
}

.bank_high_to_low_select_scroll {
  position: absolute;
  top: 37.5px;
}

@media screen and (max-width: 800px) {
  .bank_high_to_low_select {
    width: 100% !important;
    position: relative !important;
    margin-bottom: 5px !important;
  }

  .bank_high_to_low_select_scroll {
    width: 100%;
  }

  .bank_high_to_low_select_scroll_line {
    width: 98% !important;
  }

  .bank_high_to_low_select_scroll_com {
    width: 100% !important;
  }

  .owner_sort_by_acc_balance>p {
    margin-bottom: 0;
  }

  .bank_high_to_low_select_scroll_line {
    width: 94% !important;
  }
}
