.owner_connected_bank_div > p {
  width: 134px;
  height: 17px;
  text-align: left;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  margin-bottom: 5px;
}

.owner_connected_bank {
  padding: 0 !important;
  margin: 0 !important;
  width: 216px;
  text-transform: none !important;
  position: absolute;
  background-color: var(--Blue_8) !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.owner_connected_bank:hover {
  background-color: var(--Blue_8_hover)!important;
}

.owner_connected_bank_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 37px;

  padding-right: 10px;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
}

.owner_connected_bank_select > span {
  width: fit-content;
  height: 19px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  transition: all 0.2s ease;
  font: normal normal normal 14px/19px Open Sans;
}

.owner_connected_bank_scroll_com {
  width: 205px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: var(--Primary_white) !important;
  text-transform: none !important;
  margin: 3px 0px !important;
}

.owner_connected_bank_scroll_com:hover {
  background-color: var(--Blue_7) !important;
}

.owner_connected_bank_select > img {
  width: 11px;
  height: 7px;
  opacity: 1;
  transition: all 0.2s ease;
}

.OAS_owner_connected_bank_scroll_line {
  width: calc(-22px + 100%);
  /* height: 1px; */
  position: absolute;
  border-top: 1px solid var(--Blue_4);
  /* background-color: var(--Blue_4); */
  top: 39px;
  left: 10px;
}

.owner_connected_bank_scroll {
  position: absolute;
  top: 37.5px;
  padding-top: 9px;
}

@media screen and (max-width: 800px) {
  .owner_connected_bank_select {
    width: 100% !important;
  }

  .owner_connected_bank_scroll_com {
    width: 100% !important;
  }

  .owner_connected_bank_div > p {
    margin-bottom: 0;
  }
  .owner_connected_bank {
    margin-bottom: 15px !important;
  }
}
