.beneAccviewallsidebar {
  background-color: #3a5686;
  border-radius: 0 !important;
  left: 0;
  top: 170px;
  width: 100%;
  visibility: hidden;
  transition: height 0.2s ease-in, visibility 0.2s linear;
}

.beneAccviewallsidebar.open {
  visibility: visible;
  height: 310px;
  transition: all 0.2s ease-in, visibility 0s;
}

.beneAccviewallsidebar.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.beneAccviewallsidebarm {
  background-color: #314c7f;
  height: 0px;
  border-radius: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  transition: height 0.2s ease-in, visibility 0.2s linear;
}

.beneAccviewallsidebarm.open {
  visibility: visible;
  height: 100%;
  transition: all 0.2s ease-in, visibility 0s;
}

@media (min-width: 801px) {
  .beneAccviewallsidebarm {
    margin-top: 177px;
  }

  .beneAccviewallsidebarm.open {
    visibility: visible;
    height: 310px;
    transition: all 0.2s ease-in, visibility 0s;
  }
}

.beneAccviewallsidebarm.closed {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  height: 0;
  visibility: hidden;
}

.bene_acc_category_select {
  padding: 0 !important;
  width: 216px !important;
  /* height: 35px !important; */
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  /* background-color: #667ca3 !important; */
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  /* transition: all 0.2s ease !important; */
  /* margin-top: 65px !important; */
}

.bene_acc_category_selectm {
  padding: 0 !important;
  width: 90% !important;
  /* height: 35px !important; */
  text-transform: none !important;
  position: absolute !important;
  z-index: 99 !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  /* transition: all 0.2s ease !important; */
}

.beneAccviewallFilterDiv {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
  flex-direction: row;
  border-top: 1px solid #031f4f;
}

.beneAccFilterSection1 {
  display: flex;
  flex-direction: column;
  height: 200px;
  padding-right: 45px;
  width: 300px;
}

.beneAccFilterSection2 {
  display: flex;
  flex-direction: column;
  height: 225px;
  border-left: 1px solid #1d3a6d;
  padding-right: 25px;
}

.beneAccFilterSection3 {
  display: flex;
  flex-direction: column;
  height: 225px;
  border-left: 1px solid #1d3a6d;
  padding-right: 5px;
}

.upper-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  margin: 0 auto;
  padding-top: 20px;
  z-index: 1;
}

.bene_acc_Calendar_main_div {
  border-radius: 10px;
  background-color: #667ca3;

  /* margin-left: 15px; */
}

.lower-div {
  /* position: absolute;
  top: 200px;
  bottom: 0; */
  width: 100%;
  background-color: #1d3a6d;
  overflow-y: auto;
  flex-grow: 1;
  overflow-x: hidden;
}

.bene-acc-viewall-filter {
  display: flex;
  justify-content: space-between;
  padding-top: 55px;
}

/* .close-button {
    position: absolute;
    top: 120px;
    right: 10px;
} */

.close-btn-div {
  display: grid;
  justify-items: center;
  border-radius: 0.375rem;
  height: 2rem;
  width: 2rem;
  background-color: #1d3a6d;
}

.close-btn-div:hover {
  background-color: #506994;
}

/*.acc-viewall-clear-filter:hover {
  background-color: #506994;
}*/
.acc-viewall-clear-filter {
  border: 0.5px solid var(--Primary_white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acc-viewall-apply-filter {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upperDivOptions {
  position: absolute;
  top: 110px;
  width: 100%;
  /* right: 10px; */
}

.dropdown-menu {
  /* width: 296px; */
  height: 246px;
  background-color: #667ca3;
}

.bene-acc-viewall-search-close-image {
  width: 18px;
  height: 18px;
  background-color: #3a5686;
  border-radius: 10px;
}

.beneAcc_date_heading_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
}

.beneAcc_date_heading_left {
  display: flex;
  text-align: left;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 30px !important;
}

.beneAccFilterbuttonContainer {
  display: flex;
  padding-top: 212px;
}

.bene_acc_viewall_calendar_icon {
  margin-top: -2px;
  height: 15px;
  align-items: center;
  justify-content: center;
  width: 20px !important;
  /* max-width: 0; */
}

.beneAcc_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
}

.beneAcc_date_heading_right {
  display: flex;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 30px !important;
  margin-right: 30px !important;
  justify-content: end;
}

@media screen and (min-width: 1518px) and (min-height: 813px) {
  .lower-div {
    height: 582px;
    overflow: hidden;
  }

  .bene-acc-lower-div2 {
    height: 561px;
  }
}

@media screen and (min-width: 2560px) and (min-height: 1440px) {
  /* .beneTxnviewallsidebar {
    width: 15%;
  } */
  .bene-acc-lower-div2 {
    height: 585px;
  }
}

@media screen and (min-width: 3840px) {
  /* .beneTxnviewallsidebar {
    width: 10%;
  } */
  .bene-acc-lower-div2 {
    height: 609px;
  }
}

.bene_inputtxn_div_element > input {
  width: 70%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  margin-left: 10.16px;
  height: 20px;
  margin-top: -3px;
}

.bene_inputtxn_div_element {
  /* width: 175px; */
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  padding: 8px 10px 10px 10.5px;
  justify-content: space-around;
  margin: 0 9px 0 9px;
}

.bene_input_div_elementm > input {
  width: 70%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  margin-left: 10.16px;
  height: 20px;
  margin-top: -3px;
}

.bene_input_div_elementm {
  width: 94%;
  height: 30px;
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  padding: 8px 10px 10px 10.5px;
  justify-content: space-around;
  margin-top: 40px;
  margin-left: 11px;
}

.bene_acc_sort_by_category_scroll {
  max-height: 114px !important;
  /* width: 200px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 2px;
}

.bene_txn_sort_by_category_scrollm {
  max-height: 114px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 10px;
}

.bene_Acc_filter_by_category_scroll {
  max-height: 114px !important;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  padding-top: 2px;
}

.bene_acc_sort_by_category_scroll::-webkit-scrollbar {
  width: 5px;
}

.bene_acc_sort_by_category_scroll::-webkit-scrollbar-thumb {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 5px;
  height: 4px;
}

.bene_acc_sort_by_category_scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.bene_txn_category_select_select > img {
  width: 11px;
  height: 11px;
  opacity: 1;
  /* transition: all 0.2s ease; */
}

.bene_txn_category_select_select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 39px;
  cursor: pointer;
  padding: 0px 10px;
  align-items: center;

  position: absolute;
  top: 0;
}

.bene_txn_category_select_select > span {
  width: 151px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 21px;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /* transition: all 0.2s ease; */
  font: normal normal normal 14px/19px Open Sans;
}

.bene_acc_category_select_scroll_com {
  width: 198px !important;
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  cursor: pointer;
}

.bene_acc_category_select_scroll_com:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

.beneaccFilterbuttonContainerm {
  position: fixed;
  bottom: 0px;
  z-index: 999;
  background-color: #506994;
  padding: 20px;
  width: 100%;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  z-index: 9999999999 !important;
}

.beneAcc_date_heading_leftm {
  display: flex;
  text-align: left;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 16px !important;
  margin-right: 20px !important;
}

.beneAcc_date_heading_rightm {
  display: flex;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  margin-left: 25px !important;
  margin-right: 16px !important;
  justify-content: end;
}

.bene_acc_category_select_scroll_com_m {
  height: 30px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.bene_acc_category_select_scroll_com_m:hover {
  background-color: #506994 !important;
  border-radius: 5px !important;
}

@media screen and (max-width: 500px) {
  .acc-viewall-clear-filter {
    width: 50% !important;
  }

  .acc-viewall-apply-filter {
    width: 50% !important;
  }
}

@media screen and (max-width: 800px) {
  .bene_txn_category_select_select > span {
    width: 85%;
  }
}

