.slideIn {
  animation: rigthtoLeft 0.5s ease-out forwards;
}

@keyframes rigthtoLeft {
  0% {
    transform: translateX(400px);
  }

  100% {
    transform: translateX(0px);
  }
}
