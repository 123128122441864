.owner_sort_by_bank_big {
  height: 256px;
  border: 1px solid #e25869;
  border-radius: 10px;
  opacity: 1;
  z-index: 10;
  padding: 15px 0px;
  background-color: #667ca3;
  position: absolute;
}

.input_div_2 {
  height: 26px;
  /* UI Properties */
  background: #3a5686 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  padding: 5px;
  /* justify-content: space-between; */
  align-items: center;
  margin-left: 2px;
}

.input_div_2 > input {
  width: 83%;
  outline: none;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 14px/18px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  margin-left: 10px;
}

.input_div_2 > input::placeholder {
  height: 16px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 12px/16px Open Sans;
  letter-spacing: 0px;
  color: #dadada;
  opacity: 1;
  padding-left: 5px;
}

.search_owner_acc {
  width: 14px;
  height: 14px;
  opacity: 1;
  cursor: pointer;
}

.owner_bank_scroll {
  margin-top: 10px;
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 10px;
}

.bank_real_name {
  height: 39px !important;
  border-radius: 5px !important;
  margin-left: 5px !important;
  text-align: left !important;
  font: normal normal normal 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  display: flex !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}

.owner_bank_scroll::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Handle */
.owner_bank_scroll::-webkit-scrollbar-thumb {
  background: #fafafa 0% 0% no-repeat padding-box; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners of the scrollbar handle */
  width: 5px; /* Width of the scrollbar thumb */
  height: 4px; /* Height of the scrollbar thumb */
}

/* Track */
.owner_bank_scroll::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

.bank_owner_select {
  padding: 0 !important;
  margin: 0 !important;
  height: 39px !important;
  background: #667ca3 0% 0% no-repeat padding-box !important;
  text-transform: none !important;
  text-align: left !important;
  font: normal normal normal 14px/19px Open Sans !important;
  letter-spacing: 0px !important;
  color: #fafafa !important;
  display: flex !important;
  justify-content: space-between !important;

  padding: 0px 15px !important;
  position: absolute !important;
}

.arrowdown_owner {
  width: 11px;
  height: 7px;
  opacity: 1;
}

.owner_select_bank {
  padding: 0 !important;
  margin: 0 !important;
  text-transform: none !important;
  position: absolute !important;
  background-color: #667ca3 !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  transition: all 0.2s ease !important;
}

.owner_select_bank:hover{
    background-color: var(--Blue_8_hover)!important;
}

.owner_select_bank_scroll_com {
  width: 179px !important;
  height: 27px !important;
  border-radius: 5px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  text-align: left !important;
  font: normal normal normal 12px/17px Open Sans !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  text-transform: none !important;
  margin: 3px 0px !important;
  padding: 5px;
}

.owner_select_bank_scroll_com:hover {
  background-color: #506994 !important;
}
@media screen and (max-width: 800px) {
  .owner_select_bank {
    position: relative !important;
    width: 100% !important;
  }
    .input_div_2{
      width: 100%!important;
    }
}
