.NavBar-Main {
  background-color: var(--Blue_6);
  width: 100%;
  height: 10.9rem;
  position: fixed;
}

.Main-Content_In {
  width: 64rem;
  margin: auto;
  padding-top: 6.2rem;
}
.Total {
  background-color: var(--Blue_7);
  width: 8.25rem;
  height: 4.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.Main_Number {
  color: var(--Primary_white);
  font-size: var(--Font_14);
}
.Number_Count {
  color: var(--Primary_grey);
  font-size: var(--Font_12);
}
.Data-Table {
  display: flex;
  width: 64rem;
  margin: auto;
  height: 3.063rem;
  background-color: var(--Blue_7);
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
  border-radius: 0.625rem;
}
.Each-Card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.125rem;
  color: var(--Primary_grey);
  font-size: var(--Font_12);
  border-right: 1px solid var(--Blue_2);
}
.Last-Card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.125rem;
  color: var(--Primary_grey);
  font-size: var(--Font_12);
}

.Table-Data {
  display: flex;
  width: 64rem;
  margin: auto;
  height: 2.875rem;
  background-color: var(--Blue_5);
  border-radius: 0.625rem;
  padding: 0.625rem 0;
  margin-bottom: 0.625rem;
}

.first-Data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13.75rem;
  padding-left: 12px;
  font-size: var(--Font_14);
  color: var(--Primary_white);
}

.second-Data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
  color: var(--Primary_grey);
  font-size: var(--Font_14);
}
.Action-Data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13rem;
}

.imageClass {
  width: 30px;
  height: 30px;
  padding: 5px;
  gap: 10px;
  cursor: pointer;
}

.unpaid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.625rem;
  border-radius: 0.938rem;
  background-color: var(--Unpaid);
  color: var(--Button_date_range_mid_selection);
}

.paid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.563rem;
  border-radius: 0.938rem;
  background-color: var(--Paid);
  color: var(--Button_green_hover);
}
