.gst-loading-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 22.625rem; */
}

.gst-loading-loader > img {
  width: 2.8125rem;
  height: 2.3125rem;
  opacity: 1;
}

.gst-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gst-not-found > img {
  width: 320px;
  height: 226px;
  margin-top: 8rem;
  opacity: 0.9;
  margin-bottom: 1.25rem;
}

.gst-not-found p {
  color: var(--Primary_white);
  font-size: var(--Font_14);
}

/* .gst-not-found>p {
    height: 3.375rem;
    text-align: center;
    font: normal normal normal 1.25rem/1.6875rem Open Sans;
    letter-spacing: 0px;
    color: #fafafa;
    opacity: 1;
} */

.gst-default-img {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gst-default-img > img {
  margin-bottom: 1.25rem;
}

.searchInputContainerDiv {
  display: flex;
  flex-direction: row;
  padding: 0 15px;
  gap: 22px;
  border-radius: 0 !important;
}

.btnfetchele {
  width: 8rem !important;
  border-radius: 0.625rem !important;
  text-align: center !important;
  font: normal normal 600 0.875rem/1.1875rem Open Sans !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
}
.gst-clear-params-btn {
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 10px;
gap: 5px;

width: 200px;
height: 36px;

background-color: var(--Blue_8);
border-radius: 10px;



font-size: var(--Font_14);
color: var(--Primary_white) ;
margin-top: 20px
}

.gst-clear-params-btn:hover {
  background-color: var( --Blue_8_hover);
}

@media only screen and (min-width: 1024px) {
  .btnfetchele {
    width: 39px !important;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1024px) {
  .btnfetchele {
    width: 49px !important;
  }

   .gst-not-found img {
    width: 250px;
    height: 177px;
    margin-top: 180px;
  }

  .gst-not-found p {
    font-size: var(--Font_16);
  }
}

@media only screen and (max-width: 800px) {
  .btnfetchele {
    width: 100% !important;
    height: 3.063rem !important;
    border-radius: 0px !important;
    height: 2.75rem !important;
  }

  .searchInputContainerDiv {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 0px;
    border-radius: 0 !important;
  }
    .gst-not-found img {
    width: 250px;
    height: 177px;
    margin-top: 180px;
  }

  .gst-not-found p {
    font-size: var(--Font_16);
  }
}

@media only screen and (max-width: 600px) {
  .btnfetchele {
    width: 100% !important;
    height: 3.063rem !important;
    height: 2.75rem !important;
  }
     .gst-not-found img {
    width: 250px;
    height: 177px;
    margin-top: 180px;
  }

  .gst-not-found p {
    font-size: var(--Font_16);
  }
}
