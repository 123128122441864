.entities-card-main-div {
  width: 100%;
  height: auto;
}

.entities-count-div {
  max-width: 1253px !important;
  margin: 0 auto;
}

.part-one {
  flex: 28vw;
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.part-two {
  flex: 72vw;
}

.view-all-entities {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: var(--Primary_white);
}

.No-of-entities {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 100px;
  margin-top: 110px;
}

.entities-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: var(--Primary_white);
  margin-right: 4px;
  font-size: 1.125rem;
}

.entities-count {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  font-style: Open, Sans;
  margin-right: 80px;
}

.entities_filterdropdown_first_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  height: 176px;
}

.entities_filterdropdown_main_div {
  position: relative;
  width: 50%;
}

.snak-bar {
  display: flex !important;
  justify-content: space-between;
  width: 90% !important;
  margin: 0 auto;
  padding: 209px 26px 10px 8px;
}

.create-entities {
  width: 136px;
  height: 36px;
  background: var(--Primary_white) 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.crt-icon {
  font-size: 30px;
  color: var(--Blue_3);
  opacity: 1;
  margin-right: 8px;
}

.crt-label {
  width: auto;
  height: 17px;
  text-align: left;
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Blue_3);
  opacity: 1;
}

.entities-second-div {
  max-width: 1253px;
  margin: 0 auto;
  background: var(--Blue_3) 0% 0% no-repeat padding-box;
}

.infinityScrollContainerentitiesSettings {
  display: flex !important;
  flex-wrap: wrap;
  height: auto;
  overflow-y: auto;
  margin: 0 auto;
  background: var(--Blue_3) 0% 0% no-repeat padding-box;
}

.card-viewer {
  max-height: 464px;
}

.card-viewer-data {
  display: flex !important;
  flex-wrap: wrap;
  margin: 0 auto;
}

.entities-details-card {
  width: 238px;
  height: 86px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.entities-name {
  width: 210px;
  text-align: left;
  font: normal normal 600 16px/21px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 10px;
}

.entitiesViewallContainer {
  height: 240px;
}

.multiselectanimation {
  position: absolute;
  transition: all 0.2s ease-in-out;
  animation: goDowns 0.2s ease-in forwards;
  transform-origin: top center;
}

@keyframes goDowns {
  0% {
    top: -20px;
  }

  100% {
    top: 5px;
  }
}

.entities-id {
  width: 220px;
  max-width: 220px;
  text-overflow: ellipsis;
  text-align: left;
  overflow-x: hidden;
  font: normal normal 400 12px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  text-wrap: nowrap;
  margin-bottom: 4px;
  opacity: 1;
  text-transform: capitalize;
}

.entities-label {
  width: 217px;
  text-align: left;
  font: normal normal 400 10px/14px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
  display: flex;
  column-gap: 5px;
}

.entities-label > img {
  height: 12px;
  width: 12px;
}

.entitiesborderdiv {
  border-top: 1px solid var(--Blue_3);
  margin: 10px -10px;
  width: 238px;
  /* height: 1px; */
}

.loader-entities-viewall {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-entities-viewall img {
  max-width: 100%;
  height: auto;
}

.entities_div_container {
  background-color: var(--Blue_6);
  height: 105px;
  width: 100%;
  margin-top: 60px;
  transition: height 0.3s ease-in-out;
  z-index: 998;
}

.entities_div_height {
  transition: height 0.2s ease-in-out;
  height: 165px;
}

.entities_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;
  height: 105px;
  position: relative;
  align-items: center;
}

.entities_allcontainers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 113px;
  background-color: var(--Blue_7);
  height: 105px;
  transition: height 0.2s ease-in-out;
  padding: 45px 20px;
}

.entities-not-found {
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1rem;
  text-align: center;
}

#infinityScrollContainerentitiesView {
  display: flex !important;
  flex-direction: column !important;
  overflow: auto;
  position: relative;
  height: 100%;
}

.assigntagdiv {
  width: 173px;
  height: 30px;
  border-radius: 5px;
  padding: 6px 10px;
  background-color: var(--Blue_8);
  column-gap: 5px;
  color: var(--Primary_white);
  font-size: 14px;
  line-height: 19px;
  display: flex;
  cursor: default;
}

.assigntagdiv > img {
  height: 18px;
  width: 18px;
}
.entities-id > span {
  text-transform: none;
}

.entitiesCard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 68px;
  margin: 0 auto;
  row-gap: 20px;
  column-gap: 10px;
}

.entities_account_number_text_input {
  outline: none;
  background: var(--Blue_8);
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  height: 36px;
  padding: 15px;
  font: normal normal normal 12px / 17px Open Sans;
  color: var(--Primary_grey);
}

.entities_account_number_text_input::placeholder {
  color: var(--Primary_grey);
}

.entities-owner_connected_bank {
  padding: 0;
  margin: 0;
  width: 216px;
  height: 36px;
  text-transform: none;
  position: absolute;
  background-color: var(--Blue_8);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2sease;
}

.entities-owner_connected_bank:hover {
  background-color: var(--Blue_8_hover);
}

.entities-owner_filterdropdown {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px !important;
  height: 176px;
  border-top: 1px solid var(--Blue_1);
  margin: 0 auto;
  z-index: 1;
}

.entities-filter-transition-close {
  animation: goUP 300ms ease-in-out forwards;
  transform-origin: top center;
  background-color: var(--Blue_6);
}

.entities-filter-transition-default {
  display: none;
  height: 0px;
  background-color: var(--Blue_6);
}

@keyframes goUP {
  0% {
    transform: scaleY(1);
  }

  100% {
    transform: scaleY(0);
  }
}
.entities-filter-transition-open {
  visibility: visible;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  top: 90;
  position: absolute;
  background-color: var(--Blue_6);
  height: auto;
  border-radius: 0 !important;
  width: 100%;
  z-index: 998;
}

.usrfilter {
  width: 50%;
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
  align-items: end;
}

.entities-gradient {
  height: 125px;
  position: absolute;
  background-color: var(--Blue_7);
  top: 15px;
}

.entities-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  height: 140px;
  position: relative;
  align-items: center;
}

.entities-acc-viewall {
  font-family: Open Sans, Semibold;
  font-size: 20px;
  height: 28px;
  text-align: right;
  color: var(--Primary_white);
}

.No-of-entities-accounts {
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 12px;
  margin-top: 21px;
}

.entities-acc-total-count-symbol {
  transform: matrix(0, -1, 1, 0, 0, 0);
  font-style: italic;
  color: var(--Primary_white);
  margin-right: 4px;
  font-size: 1.125rem;
}

.view-count-entities {
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  font-style: Open, Sans;
}

.search-bar-hover {
  z-index: 999;
}

.entities-buttoncontainer {
  margin: 0 auto;
  display: flex;
  padding-left: 20px;
}

.entities-clear {
  width: 87px;
  height: 25px;
  border: 0.5px solid var(--Primary_white);
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
}

.entities-apply {
  margin-left: 15px;
  width: 87px;
  height: 25px;
  border-radius: 8px;
  padding: 3px 27px;
  opacity: 1;
}

.entities_btn_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  width: 110px;
  padding-top: 45px;
}

.entities-buttoncontainer-text {
  width: 28px;
  height: 17px;
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
}

.entitiesfilter {
  width: 50%;
  display: flex;
  justify-content: end;
  padding-bottom: 20px;
  align-items: end;
}

.entities-containers {
  height: 30px;
  width: 50px;
  padding: 9px 6px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  background: var(--Blue_4);
  border-radius: 5px;
  opacity: 1;
}

.entities-containers:hover {
  background-color: var(--Blue_7);
}

.entities_search_div {
  width: 50px;
  height: 30px;
  background-color: var(--Blue_4);
  border-radius: 5px;
  padding: 7px 9px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.entitiesfilter-images {
  width: 12px;
  height: 12px;
}

.entitiessearch-images {
  width: 14px;
  height: 14px;
}

.entitiesfilter_badge {
  width: max-content;
  max-width: 170px;
  height: 36px;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  border: 2px solid var(--Blue_8);
  border-radius: 48px;
  display: flex;
  gap: 10px;
  padding: 7px 10px;
}

.entitiesfilter_badge_text {
  width: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 92px;
  height: 18px;
  text-align: center;
  font: normal normal 400 12px/19px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
}

.entitiesfilter_badge_img {
  margin-top: 1px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.entitiesfilter_badge_div {
  display: flex;
  gap: 10px;
  width: auto;
}

.entitesfilterby_text {
  width: 76px;
  height: 19px;
  font: normal normal normal 12px/16px Open Sans;
  padding-top: 10px;
  color: #fafafa;
}

.entities_applied_div {
  height: 60px;
  background-color: var(--Blue_6);
}

.entitiesviewallscroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.entitiesviewallscroll::-webkit-scrollbar-track {
  height: 4px;
}

/* Handle */
.entitiesviewallscroll::-webkit-scrollbar-thumb {
  height: 4px !important;
  background-color: #fafafa;
  outline: 1px solid var(--Blue_5);
  border-radius: 25px;
}

.entities_back_btn {
  display: none;
}

.entities_no_of {
  font: normal normal 600 16px/22px Open Sans;
  margin-top: 8px;
  width: 120px;
  display: flex;
  justify-content: space-between;
}

.entities_views-counts {
  width: 100px;
  font: normal normal normal 12px/17px Open Sans;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  letter-spacing: 0px;
  color: var(--Primary_grey);
}

@media only screen and (min-width: 800px) and (max-width: 1050px) {
  .entities_div,
  .entities-owner_filterdropdown {
    padding: 0 20px;
  }

  .entities_applied_div {
    padding: 0 20px;
    overflow-y: hidden;
  }

  .entities_applied_div {
    height: 90px;
  }
}

@media screen and (min-width: 1920px) {
  .entities_applied_div {
    height: 60px;
  }
}

@media (max-width: 800px) {
  .entities_back_btn {
    cursor: pointer;
    display: flex;
    width: 35px;
    align-items: center;
    height: 100%;
  }

  .entities_back_btn > img {
    height: 16px;
    width: 16px;
    rotate: 90deg;
  }

  .entitiesCard {
    margin: 0;
    padding: 20px 15px;
    width: 100%;
    overflow-x: hidden;
  }

  .entities_div_container {
    height: 60px;
    width: 100%;
    margin-top: 0px;
  }

  .entities_div_height {
    height: 115px;
  }

  .entities_div {
    padding: 0 20px;
    height: 60px;
  }

  .entities_allcontainers {
    width: 120px;
    flex-direction: row;
    height: 60px;
    padding: 0px;
    background-color: transparent;
  }

  .entities_btn_container {
    flex-direction: row-reverse;
    height: 60px;
    padding-top: 0px;
    width: 90px;
  }

  .entities-containers {
    height: 35px;
    background-color: transparent;
    width: 40px;
    position: relative;
  }

  .entities-containers::after {
    content: "";
    top: 2px;
    position: absolute;
    right: -5px;
    height: 90%;
    border-right: 1px solid var(--Blue_3);
  }

  .entities_containers_responsive {
    border-radius: 0;
  }

  .entities-containers:hover,
  .entities_search_div:hover {
    background-color: transparent;
  }

  .entities_search_div {
    height: 35px;
    width: 40px;
    background-color: transparent;
    justify-content: center;
    align-items: center;
  }

  .entities-containers > div + div,
  .entities_search_div > img + img {
    display: none;
  }

  .entitiesfilter-images,
  .entitiessearch-images {
    width: 16px;
    height: 16px;
  }

  .entities-filter-transition-open {
    height: calc(100% - 60px);
    overflow-y: hidden;
  }

  .entities-filter-transition-open > div {
    height: 100%;
  }

  .entities-owner_filterdropdown {
    height: 100%;
    padding: 0 15px;
  }

  .entities_filterdropdown_first_div {
    flex-direction: column;
    height: 100%;
  }

  .entities_filterdropdown_main_div,
  .entities-owner_connected_bank {
    width: 100%;
  }

  .entities_applied_div {
    padding: 0 15px;
    overflow-y: hidden;
  }

  .entities_applied_div {
    height: 80px;
  }

  .entitiesfilter {
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .entities-buttoncontainer {
    padding-left: 0px;
  }

  .entities-buttoncontainer {
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 69px;
    background-color: var(--Blue_7);
    border-radius: 20px 20px 0 0;
    padding: 15px 20px;
  }

  .entities-clear,
  .entities-apply {
    width: 145px;
    height: 39px;
    border-radius: 20px;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .entities_back_btn + div {
    width: 85px;
  }
}

@media (max-width: 550px) {
  .entities-details-card,
  .entities-details-card > div {
    width: 100%;
  }

  .entities-name {
    width: max-content;
  }
}
