.allBenefiDashboard {
  --tw-bg-opacity: 1;
  position: relative;
  left: 0;
  width: 100%;
  /*height: 200px;*/
  margin-top: 0px;
}

.allBenefiDashboardheader {
  width: 100%;
  display: table;
  background-color: #314c7f;
  padding: 20px;
}

.allBenefiDashboardheaderimg {
  width: 15%;
  display: table-cell;
  vertical-align: middle;
}

.allBenefiDashboardheadertext {
  width: 85%;
  display: table-cell;
  vertical-align: middle;
  padding: 0px 10px;
}

.headertexttop {
  font: normal normal 600 26px/39px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 20px;
}

.headertextbottom {
  font: normal normal 600 14px/23px Open Sans;
  letter-spacing: 0px;
  color: #c9c9c9;
  margin-top: -13px;
  margin-left: 2px;
  font-size: 14px;
}

@media (min-width: 801px) {
  .allBenefiDashboard {
    margin-top: 60px;
  }
  .BeneNavMenu {
    display: block;
  }
  .AccDashboardMain {
    overflow: hidden;
  }
}

.BeneNavMenu,
.BeneNavMenuMobile {
  opacity: 1;
}

.gridlayout {
  margin: auto;
  max-width: 1024px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px 0px;
  /* Adjust as needed 
  grid-template-columns: repeat(7, minmax(0, 1fr));*/
  overflow: auto;
  padding: 2em 0rem 0rem 0rem;
  scrollbar-width: none;
}

@media (min-width: 801px) {
  .gridlayout {
    padding: 4.2rem 0rem 0rem 0rem;
  }
}

.gridlayout {
  width: 95%;
}

@media (min-width: 980px) {
  .gridlayout {
    width: 90%;
  }
}

@media (min-width: 1401px) {
  .gridlayout {
    width: 70%;
  }
}

.BenefiMain {
  width: 100%;
  height: 100%;
  margin-top: -1px;
}

@media (min-width: 801px) {
  .BenefiMain {
    background: linear-gradient(to right, var(--Blue_3) 65%, var(--Blue_4) 35%);
  }
}

@media (min-width: 1440px) {
  .BenefiMain {
    background: linear-gradient(to right, var(--Blue_3) 61%, var(--Blue_4) 39%);
  }
}

.BenefiSection {
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: auto;
  height: 90%;
  background: linear-gradient(to right, var(--Blue_3) 60%, var(--Blue_4) 40%);
}

.BenefiSectionr {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  height: 100%;
}

.datacard {
  background-color: red;
  margin-top: 6px;
  width: 129px;
  height: 118px;
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin: 0px 7px;
}

.cardpage {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 90px;
  position: relative;
  top: -33px;
}

.colorpage {
  width: 33px;
  height: 29px;
  margin-left: 96px;
  background: var(--Failed) 0% 0% no-repeat padding-box;
  border-radius: 0px 21px;
  opacity: 1;
}

.word1 {
  width: 50px;
  height: 60px;
  text-align: left;
  font: normal normal 600 38px/60px Open Sans;
  letter-spacing: 0px;
  color: var(--Pending);
  opacity: 1;
}

.word2 {
  width: 85px;
  height: 35px;
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 14px/16px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  height: 12px !important;
  margin-bottom: 0px !important;
}

.rev2 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 65%;
  display: flex;
  height: 100vh;
}

.rev3 {
  background: var(--Blue_4) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 35%;
  display: flex;
  height: 100%;
}

.p1 {
  display: flex;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 60%;
}

.BenefContent {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 66%;
  /* margin-left: 22%; */
  /* gap: 12%; */
  height: 90%;
  /* margin: auto; */
}

.expentraloaderwithDashBoard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  img {
    width: 49px;
    height: 50px;
  }
}

.beneNotFound {
  display: flex;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  width: 60%;
  gap: 12%;
  margin-top: 10px;
}

.beneNotFoundr {
  width: 100%;
}

.benGridLaySection {
  display: flex;
  flex-direction: row;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  height: 75vh;
  margin: 0px auto;
}

.benGridLaySectionr {
  display: flex;
  flex-direction: row;
  width: 97%;
}

.box1 {
  width: 50%;
  padding: 10px 5px;
  gap: 65px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  padding: 10px 0px;
  height: 100vh;
}

.box2 {
  width: 50%;
  padding: 10px 5px;
  --tw-bg-opacity: 1;
  background-color: rgba(29, 58, 109, var(--tw-bg-opacity));
  height: 100vh;
}

.container1 {
  display: flex;
  margin-top: 30px;
  height: 100%;
  justify-content: space-between;
  border-radius: 40px;
  align-items: center;
  font-size: 18px;
  color: white;
  width: 100%;

  .mspan {
    font: normal normal normal 16px/20px Open Sans;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 11px;
  }
}

.container2 {
  padding-left: 1.5rem;
  display: flex;
  margin-top: 3%;
  height: 100%;
  justify-content: space-between;
  border-radius: 40px;
  align-items: center;
  font-size: 12px;
  color: white;
  width: 100%;
  span {
    font-size: 15px;
  }
}

.btn {
  width: 100%;
  height: 100%;
  border-radius: 40px;

  .btnp {
    display: flex;
    align-items: center;

    .btn1 {
      position: relative;
      left: -2%;
      width: 100%;
      height: 17px;
      margin-left: 20px;
      font: normal normal normal 13px/17px Open Sans;
      color: var(--Primary_white);
    }

    img {
      width: 12px;
      height: 12px;
      margin-right: 17px;
    }
  }
}

.BeneAcountRightSec {
  display: flex;
  flex-direction: column;
  gap: 1%;
  --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity));
  width: 34%;
  height: 90%;
  /* background-color: #000; */
}

#webviewd {
  --tw-bg-opacity: 1;
}

@media only screen and (min-width: 800px) {
  .webviewCreateAccount {
    padding-right: 24px;
  }
}

.BeneAcountRightSecr {
  /* --tw-bg-opacity: 1;
  background-color: rgba(36, 66, 119, var(--tw-bg-opacity)); */
  width: 100%;
  /* height: 80%; */
  height: 90vh;
  margin-left: 10px;
  color: #ffffff !important;
}

.BeneAcountleftSecr {
  --tw-bg-opacity: 1;
  /* background-color: rgb(29, 58, 109, var(--tw-bg-opacity)); */
  width: 100%;
  /* height: 80%; */
  height: 90vh;
  /* padding-bottom: 120px; */
}

@media (min-width: 801px) {
  .BeneAcountleftSecr {
    padding-bottom: 30px;
  }
}

.createAc {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 3%;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 114px;
    height: 22px;
    margin-top: 21px;
    margin-bottom: 22px;
    text-align: center;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #ffffff !important;
    opacity: 1;
  }
}

.QuickActionSec {
  width: 100%;
  height: 90%;
}

.leftBenfContent {
  width: 100%;
  max-width: 308px;
  background-color: #314c7f;
  height: 37px;
  padding: 15px 0px;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  align-items: center;
  display: flex;
  justify-content: center;

  p {
    width: 74pxs;
    height: 17px;
    text-align: center;
    font: normal normal normal 12px/17px Open Sans;
    letter-spacing: 0px;
    color: #c9c9c9;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.RightBenfContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0.4;
  padding: 10px 20px;
}

@media only screen and (min-width: 530px) and (max-width: 800px) {
  .RightBenfContent {
    margin-top: -6px;
  }
}

.leftSidecontent {
  display: flex;
  width: 60% !important;
  align-items: center;
  gap: 5%;
  margin-top: 4%;

  img {
    width: 25px;
    height: 25px;
    opacity: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  p {
    width: 120px !important;
    height: 17px;
    text-align: left;
    font: normal normal normal 12px/17px Open Sans !important;
    letter-spacing: 0px;
    color: #ffffff !important;
    opacity: 1;
  }
}

.RightSideContent {
  display: flex;
  justify-content: flex-end;

  justify-content: center;
  align-items: center;
  margin-top: 4%;
  padding: 4px 0px;
  background-color: rgba(2, 2, 19, 0.171);
  border-radius: 6px;
  width: 20px;
  height: 20px;

  img {
    width: 6px;
    height: 11px;
    color: white;
  }
}

.arrowfunc {
  display: flex;
  justify-content: center;
  background-color: var(--Blue_4);
  width: 20%;
  height: 30%;
}

.gridcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  max-height: 30%;
  width: 655px;
  row-gap: 20px;
  column-gap: 20px;
}

.griditem {
  max-width: 654px;
  max-height: 42px;
  background: var(--Blue_6) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  margin: 0px auto 20px auto;
}

.itemcontent1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 42px;
  max-width: 654px;
  border-radius: 42px;
  position: relative;
}

.textleft1 {
  margin-right: 12px;
  font-size: 14px;
  color: rgb(162, 238, 21);
  white-space: nowrap;
  width: 80%;

  .lelfP {
    margin-left: 20px;
    margin-top: 14px;
    margin-bottom: 10px;
    height: 22px;
    text-align: left;
    font: normal normal normal 14px/22px Open Sans;
    letter-spacing: 0px;
    color: var(--Settled);
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: lowercase;
  }

  .pendingColor {
    color: var(--Pending);
  }

  .deniedColor {
    color: var(--Failed);
  }

  .approvedColor {
    color: var(--Settled);
  }

  .archivedColor {
    color: var(--Abandoned);
  }

  .blockColor {
    color: var(--Failed);
  }

  .lelfP::first-line {
    text-transform: capitalize !important;
  }
}
@media only screen and (max-width: 980px) {
  .lelfP {
    flex-grow: 1;
    width: 170px;
  }
  .textleft1 {
    display: flex;
  }
}
@media only screen and (min-width: 981px) and (max-width: 989px) {
  .lelfP {
    width: 150px;
  }
}
@media only screen and (min-width: 989px) {
  .lelfP {
    width: 165px;
  }
}
@media only screen and (min-width: 989px) and (max-width: 1000px) {
  .textleft1 {
    width: 65%;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1040px) {
  .textleft1 {
    width: 70%;
  }
}
@media only screen and (min-width: 802px) and (max-width: 865px) {
  .textleft1 {
    width: 77%;
  }
}
@media only screen and (min-width: 499px) and (max-width: 570px) {
  .textleft1 {
    width: 77%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 499px) {
  .textleft1 {
    width: 75%;
  }
}

@media only screen and (min-width: 468px) and (max-width: 480px) {
  .textleft1 {
    width: 75%;
  }
}
@media only screen and (min-width: 405px) and (max-width: 468px) {
  .textleft1 {
    width: 70%;
  }
}

@media only screen and (max-width: 405px) {
  .textleft1 {
    width: 67%;
  }
}

.imageright1 {
  position: absolute;
  display: flex;
  width: 102px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  /* top: 5.3px; */
  right: 0px;
  bottom: 0px;
  border-top-left-radius: 22px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 20px;

  img {
    width: 78px;
    height: 20px;
    opacity: 1;
    margin-top: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
recentSectionr {
  display: flex;
  max-width: 654px;
  justify-content: space-between;
  height: 42px;
  background: #234176 0% 0% no-repeat padding-box;
  border-radius: 21px;
  opacity: 1;
  width: 95%;
}
@media only screen and (max-width: 800px) {
  .recentSectionr {
    margin: 0px auto 20px auto;
  }
}

@media only screen and (min-width: 800px) {
  .recentSectionr {
    margin: 30px auto;
  }
}
.recentSectionr {
  display: flex;
  max-width: 654px;
  justify-content: space-between;
  height: 36px;
  background: #234176 0% 0% no-repeat padding-box;
  border-radius: 21px;
  opacity: 1;
  width: 100%;
  margin: 20px 0;
}

.recenttext {
  width: 51px;
  height: 22px;
  margin-left: 20px;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
  text-align: left;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_grey);
  opacity: 1;
}

.viewall {
  width: 94px;
  height: 36px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  border: 1.6px solid var(--Failed);
  border-radius: 21px;
  opacity: 1;
  cursor: pointer;
}

.viewall p {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  white-space: nowrap;
}

.viewall img {
  width: 10px;
  height: 10px;
  color: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.accBeneTxn_NextAndPrevIcon {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 15px;
  width: 55x;
  float: right;
}

.accBeneTxn_CarouPrevIcon {
  img {
    width: 20px;
    height: 20px;
    opacity: 1;
    border-radius: 32px;
    cursor: pointer;
  }
}

.accBeneTxn_CarouNextIcon {
  img {
    width: 20px;
    height: 20px;
    border-radius: 32px;
    cursor: pointer;
  }
}

@media screen and (max-width: 800px) {
  .allBenefiDashboardheader {
    background: var(--Blue_6) 0 0 no-repeat padding-box;
    display: flex;
    height: 60px;
    left: 0;
    padding: 10px 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
  .BeneTxn_AccDashBoard {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 105px;
  }
  .BeneAccmain {
    background: var(--Blue_3) !important;
  }
  .BeneNavMenu {
    display: none;
  }
  .allBenefiDashboardMob {
    position: relative;
    left: 0;
    width: 100%;
    margin-top: 0px;
    margin-top: 130px;
    display: block !important;
  }
  .BeneAcountleftSecr {
    height: 100% !important;
  }
}

#mobileviewAccDashboard {
  display: none;
}

@media only screen and (min-width: 321px) and (max-width: 801px) {
  #mobileviewAccDashboard {
    display: block;
    margin-left: 4px;
  }
}
