.content-page {
  width: 100%;
  /* background-color: var(--Blue_6);
  height: 305px; */
  margin-top: 72px;
  /* padding: 6px;
  box-shadow: 5px 5px 11px #00000026; */
}

.content-page-Responsive {
  width: 100%;
  background-color: var(--Blue_6);
  height: 430px;

  /* padding: 6px; */
  box-shadow: 5px 5px 11px #00000026;
}

.container {
  width: 100%;
  background-color: var(--Blue_6);
  height: auto;
  margin: auto;
}

.ownerDetail-container {
  width: 100%;
  background-color: var(--Blue_6);
  height: auto;
}

.container-Responsive {
  width: 100%;
  background-color: var(--Blue_6);

  /* height: 30%; */

  margin: auto;
}

.acc-section {
  height: auto;
  /* background-color: var(--Blue_6); */
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.acc-section-responsive {
  height: 30%;
  background-color: var(--Blue_5);
  max-width: 1024px;
  margin: auto;
  display: flex;
}

.major-div {
  width: 100%;
  margin-top: 32px;
}

.major-div-responsive {
  width: 100%;
  margin-top: 20px;
}

.individual-bank-detail {
  height: auto;
  display: flex;
  border-bottom: 2px solid var(--Blue_3);
}

.individual-bank-detail-responsive {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--Blue_3);
  margin-top: 50px;
  /* padding-bottom: 12px; */
}

.acc-balance {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-bottom: 25px;
  width: 50%;
  position: relative;
}

.view-Balance {
  border-top: 2px solid var(--Blue_3);
  margin-bottom: 28px;
  width: 100% !important;
  padding-top: 15px;
}

.acc-balance-Responsive {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  /* width: 38%; */
  width: 100%;
  height: auto;
  position: relative;
}

.acc-balance-increased-Responsive {
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  display: flex;
  /* width: 38%; */
  width: 100%;
  /* height: auto; */
}

.arrow-div {
  position: absolute;
}

.arrow-div-up {
  position: absolute;
  left: 100px;
  top: 40px;
}

.arrow-div-down {
  position: absolute;
  left: 95px;
  bottom: 70px;
}

.acc-number-balance {
  width: 235px;
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 0.75rem;
  margin-left: 20px;
  margin-top: -22px;
}

.refresh-div {
  display: flex;

  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
  cursor: pointer;
}
.refresh-div img {
  color: var(--Primary_white) !important;
  height: 14px;
  width: 14px;
  margin-top: 10px;
  
}

.refresh-amt-div {
  position: relative; /* Ensure the parent is positioned */
  margin-right: 5px;
}


.refresh-amt-div::after {
  content: "";
  position: absolute;
  background-color: var(--Primary_white);
  width: 1px;
  height: 15px; /* Adjust this to control the length of the border */
  top: 50%;
  right: 0;
  transform: translateY(-50%); /* Centers it vertically */
}


.refresh-div-Responsive {
  display: flex;

  justify-content: space-between;
  align-items: center;
  height: 15px;
  margin-left: 6px;
}

.refresh-img-icon {
  color: var(--Primary_white) !important;
  height: 33px;
  width: 33px;
  margin-top: 2px;
}

.refresh-text-content {
  font: normal normal normal 11px/26px Open Sans;
  color: #fafafa;
}

.individual-account {
  margin-top: 21px;
  margin-bottom: 21px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
  border-right: 2px solid var(--Blue_3);
}

.individual-account-Responsive {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 0 20px 15px 20px;
  /* padding-left: 20px;
  padding-bottom: 15px; */
  border-bottom: 2px solid var(--Blue_3);
}

.accno-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 3px;
}

.popup-header {
  font: normal normal 600 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  margin-bottom: 3px;
}

.acc-number-label {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 12px;
  font-weight: 400;

  font: Open Sans;
  letter-spacing: 0px;
}

.acc-balance-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: 600;
  /* border-left: 2px solid var(--Blue_3); */
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.acc-balance-content-Reponsive {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  font-weight: 400;

  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.bank-details {
  height: 90px;
  display: flex;
}
.bank-detailsDiv {
  width: calc(100% - 102px);
}

.bank-details-Responsive {
  height: 140px;
  display: flex;
  flex-direction: column;
}

.bank-logo-details {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 6.375rem;
}

.bank-logo-details-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-bank-container {
  background: var(--Primary_white);
  height: 88.5px;
  width: 6.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-bank-container-responsive {
  background: var(--Primary_white);
  height: 31px;
  width: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.detailed-bank-logo {
  width: 80px;
}
.detailed-bank-logo-responsive {
  width: 58px;
  height: 15px;
}

.individual-account-holdername {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
  margin-bottom: 5px;
}

.owner-loader-container {
  img {
    margin-top: 350px !important;
    width: 49px;
    height: 50px;
  }
}

.minor-div {
  width: 373px;

  margin-top: 29px;
}

.acc-content-detail-page {
  justify-content: center;
  height: 48px;
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.acc-status-main {
  border-bottom: 2px solid var(--Blue_3);
  height: 90px;
  display: flex;
}

.acc-status-main-responsive {
  /* border-bottom: 2px solid var(--Blue_3); */
  height: 70px;
  display: flex;
}

.acc-connected {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 1px;
  font-weight: 600;
  /* border-right: 2px solid var(--Blue_3); */
}

.border-right {
  border-right: 2px solid var(--Blue_3);
}
.acc-connected-responsive {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 20px;
  font-weight: 600;
}

.acc-status-detail-page {
  padding: 25px 0px 20px !important;

  width: 50%;
}

/* .acc-status-detail-page-responsive {
  padding-bottom: 10px;
} */

.acc-status-content {
  color: #fafafa;
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid var(--Blue_3);
  height: 47px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
}

.acc-balance-content-responsive {
  color: #fafafa;
  opacity: 1;
  /* font-size: 1.2rem; */
  font-weight: 400;
  height: 47px;
  align-items: flex-start;
  padding-left: 20px;
}

.acc-balance-increased-content-responsive {
  color: #fafafa;
  opacity: 1;
  /* font-size: 1.2rem; */
  font-weight: 400;
  height: 47px;
  align-items: flex-start;
  padding-left: 20px;
}

.active-status {
  color: var(--Settled);
}

.not-active-status {
  color: #ff5860;
}

.recipient {
  height: auto;
  border-bottom: unset;
  display: flex;
  align-items: center;
}

.recipient-box2 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 6.375rem;
  border-right: 2px solid var(--Blue_3);
}
.recipient-boxTrans-resp {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  width: 6.375rem;
  border-right: 2px solid var(--Blue_3);
}

.akh[disabled] {
  background-color: var(--Blue_7) !important;
}

.recipient-box1 {
  /* background: var(--Blue_7) 0% 0% no-repeat padding-box; */
  opacity: 1;
  width: 6.375rem;
  /* margin-bottom: 5px; */
  border-right: 2px solid var(--Blue_3);
  height: 100%;
}

.recipient-box3 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 6.375rem;
  height: 100%;
}

.recipient-boxTrans {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  width: 6.4rem;
}

.recipient-box5 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 102px;
  height: 100%;
}

.recipient-box5-responsive {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.recipient-box5-responsive {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.recipient-box6 {
  /* background: var(--Blue_7) 0% 0% no-repeat padding-box; */
  /* opacity: 1; */
  width: 6.375rem;
  /* cursor: pointer; */
  height: 100%;
}

.recipient-box6-responsive {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  border-left: 2px solid var(--Blue_3);
  width: 34.3%;
}

.recipient-box4 {
  background: var(--Blue_7) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 33%;
}

.recipient-img {
  width: 30px;
  height: 30px;
}

.image-label {
  font-size: 10px;
  font-weight: 400;
  color: var(--Primary_white) !important;
}

.title2 {
  color: var(--Primary_grey);
  margin-bottom: 10px;
}

.statement {
  height: 90px;
  border-bottom: 2px solid var(--Blue_3);
}

.empty-statement {
  width: 66%;
  background-color: var(--Blue_6);
}

.statement-view-acc {
  height: 90px;
}

.statement-img {
  background: var(--Blue_7);
  opacity: 1;
}

.signature-error-div {
  width: 68%;
  background-color: var(--Blue_6) !important;
}

.details-div-section {
  width: 100%;
  background-color: var(--Blue_3);
  margin-top: 20px !important;
  padding-bottom: 1px;
}

.details-div-section-responsive {
  width: 100%;
  background-color: var(--Blue_5);
  margin-top: 20px !important;
  margin-bottom: 20px;
}

.details-section {
  height: auto;
  background-color: var(--Blue_3);
  max-width: 1024px;
  margin: auto;
}

.details-section-responsive {
  height: 39px;
  background-color: var(--Blue_3);
  max-width: 1024px;
  margin: auto;
}

.details-btn {
  width: 80px;
  height: 30px;
  background-color: var(--Primary_white);
  border-radius: 18px;
  margin-right: 20px;
  color: #3a3649;
  font-size: 0.75rem;
  text-align: center;
  font-style: Open, Sans;
  font-weight: 600;
  text-align: center;
}

.bank-detail-card {
  width: 328px;
  height: 122px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
}

.bank-detail-card-responsive {
  width: auto;
  height: 118px;
  margin-bottom: 20px;
  background-color: var(--Blue_5);
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.statistics-second-card-p1 {
  color: var(--Primary_white);
  font-size: 14px;
  font-weight: 600;
}

.statistics-second-card-p1-total {
  color: var(--Primary_white);
  font-size: 12px;
  font-weight: 600;
}

.statistics-second-card-p2 {
  color: var(--Primary_grey);
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
}

.statistics-second-card-p3 {
  color: var(--Primary_white);
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
}

.statistics-second-card {
  width: 64rem;
  position: relative;
  background-color: var(--Blue_6);
  border-radius: 20px;
  padding-bottom: 20px;
  padding: 20px;
}

.statistics-second-card-top-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--Blue_3);
  height: 5.125rem;
}

.statistics-second-card-middle-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.statistics-second-card-header-bar {
  display: flex;
  flex-direction: row;
  background-color: var(--Blue_7);
  height: 50px;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
}
.statistics-second-card-header-bar > div {
  font-size: 12px;
  color: var(--Primary_grey);
  flex: 20%;
  font-weight: 400;
  text-align: center;
}

.statistics-second-card-data-bars {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.statistics-second-card-data-bars > div {
  font-size: 12px;
  flex: 20%;
}

.statistics-second-card-data-div {
  transition: height 0.2s ease-in, visibility 0s linear 0.2s;
  max-height: 25.313rem;
  min-height: 12.55rem;
  margin-right: -20px;
  padding-right: 16px;
}

.statistics-second-card-data-div-date {
  color: var(--Primary_white);
  text-align: center;
  padding-right: 20px;
  font-weight: 400;
}

.statistics-second-card-data-div-amount1 {
  color: var(--Primary_white);
  text-align: right;
  padding-right: 25px;
  font-weight: 400;
}
.statistics-second-card-data-div-amount2 {
  color: var(--Primary_white);
  text-align: right;
  padding-right: 17px;
  font-weight: 400;
}
.statistics-second-card-data-div-amount3 {
  color: var(--Primary_white);
  text-align: right;
  padding-right: 10px;
  font-weight: 400;
}

.statistics-second-card-data-div-income {
  color: var(--Queued);
  text-align: right;
  font-weight: 600;
}

.statistics-second-card-span {
  color: var(--Queued);
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  right: 0;
  position: absolute;
}

.statistics-second-card-header-bar-div2 {
  border-left: 1px solid var(--Blue_3);
  border-right: 1px solid var(--Blue_3);
  height: 50px;
  padding-top: 15px;
}

.statistics-first-card {
  width: 64rem;
  position: relative;
  background-color: var(--Blue_6);
  border-radius: 20px;
  padding-bottom: 20px;
  padding: 20px;
}

.statistics-first-card-left {
  border-right: 1px solid var(--Blue_3);
}

.assigned-detail-card {
  width: 1024px;
  position: relative;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 30px !important;
}

.statistics-second-card-no-data {
  height: 25.313rem;
  display: flex;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding: 6.125rem 16.375rem 6.063rem 16.313rem;
}

.statistics-second-card-no-data-img {
  width: 13.188rem;
  height: 11.25rem;
}

.statistics-second-card-no-data-text {
  margin-top: 20px;
  color: var(--Primary_white);
  font-weight: 400;
  font-size: 14px;
  width: 17.5rem;
}

.statistics-first-card-right-text {
  color: var(--Primary_white);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
}

.statistics-first-card-right-div {
  width: 302px;
}

.statistics-first-card-left-div {
  width: 659px;
}

.statistics-graph-no-data-img {
  width: 13.188rem;
  height: 11.25rem;
}

.statistics-graph-no-data {
  display: flex;
  align-content: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  padding-top: 60px;
}

.statistics-graph-no-data-text {
  margin-top: 20px;
  color: var(--Primary_white);
  font-weight: 400;
  font-size: 14px;
  width: 17.5rem;
}

.assigned-detail-card-responsive {
  width: 1024px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  background-color: var(--Blue_5);
  border-radius: 10px;
  box-shadow: -5px -5px 11px #71717133;
  padding: 10px;
  margin-bottom: 30px !important;
}

.bank-card-row {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.bank-card-row-responsive {
  flex-direction: row;
  margin: 20px 0px 0px 0px;
  gap: 20px;
  padding-bottom: 100px !important;
}

.assigned-card-row {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.assigned-card-row-responsive {
  display: flex;
  padding-bottom: 100px;
  flex-direction: row;
  margin: 20px 0px 30px 0px;
  gap: 20px;
}

.ifsc-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  width: 125px;
}

.pan-response {
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  width: 125px;
}

.data-div {
  font-size: 0.9rem;
  color: var(--Primary_white);
  padding-bottom: 4px;
}

.basic-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: #c9c9c9;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--Blue_3);
  width: 100%;
}

.accno-details {
  padding-top: 27px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 1px;
}

.accno-details-img {
  background-color: var(--Primary_white);
  width: 82px;
  height: 26px;
  border-radius: 20px;
  margin-right: 20px;
  margin-top: 5px;
}

.accno-details-detailed-bank-logo {
  padding: 5px 10px;
}

.accno-details-Reponsive {
  /* padding-top: 10px;
  padding-bottom: 10px; */
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  width: auto;
  padding-left: 20px;
  border-bottom: 2px solid var(--Blue_3);
}

.ifsc-div-main {
  padding-bottom: 20px;
  padding-top: 20px;
  width: 50%;

  /* height: 80px; */
}

.ifsc-div-main-Resposive {
  /* padding-bottom: 10px;
  padding-top: 10px; */
  width: auto;
  border-bottom: 2px solid var(--Blue_3);
  display: flex;
  justify-content: space-between;
}

.ifsc-div-content {
  padding-top: 5px;
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  border-left: 2px solid var(--Blue_3);
  height: 50px;
  width: 200px;
  padding-right: 10px;
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.pan-div-content {
  padding-top: 5px;
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  height: 55px;
  width: 298px;
  padding-right: 10px;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pan-div-content-responsive {
  padding-top: 5px;
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  height: 55px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.ifsc-div-content-Responsive {
  /* padding-top: 5px; */
  color: var(--Primary_white);
  opacity: 1;
  font-size: 1.2rem;
  padding-top: 15px;
  padding-bottom: 15px;

  /* height: 50px; */
  /* width: 200px; */
  padding-right: 10px;
  display: flex;

  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.ifsc-div-main:hover {
  width: 144px;
  height: 59px;
}

.ifsc-code-element {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 0.75rem;

  margin-top: 2px;
  font-size: 12px;

  /* padding-bottom: 10px; */
  width: 100%;
}

.pan-number-element {
  color: var(--Primary_grey);
  opacity: 1;
  font-size: 0.75rem;

  margin-top: 2px;
  font-size: 12px;

  /* padding-bottom: 10px; */
  width: 100%;
}

.acc-type-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: var(--Primary_grey);

  width: 100%;
}

.owner-div {
  padding-bottom: 10px;
  font-size: 0.8rem;
  color: var(--Primary_grey);

  width: 100%;
}

.statementNotFoundError {
  height: 90px;
  background: var(--Failed_to_take_action) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.owner-detail-card {
  width: 328px;
  height: 253px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
}

.support-detail-cards {
  width: 328px;
  height: 122px;
  background-color: var(--Blue_5);
  border-radius: 20px;
  padding: 20px;
}

.support-detail-card-responsive {
  /* width: 328px; */
  width: auto;
  /* height: 250px; */
  background-color: var(--Blue_5);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.buttonsforOwnerdetails {
  display: flex;
  align-items: center;
}

.buttonsforOwnerdetails-responsive {
  display: flex;
  height: 39px;
  align-items: center;
  background-color: var(--Blue_5);
  width: 100% !important;
}

.DetailsOwnerButton {
  background-color: var(--Detail_chip);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DetailsOwnerButton-responsive {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
}

.AssignedButton {
  background-color: var(--Detail_chip);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AssignedButton-responsive {
  height: 39px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.owner_div_Acc {
  font-size: 0.8rem;
  color: var(--Primary_grey);
  padding-bottom: 20px;
  width: 100%;
}

/* .bank-logo-details::after {
  content: "";
  position: absolute;
  background-color: var(--Blue_3);
  width: 1px;
  height: 60px;
  transform: translateY(25%);
  right: 0;
} */

.title5 {
  font-weight: 600;
  color: var(--Primary_grey);
  font-size: 14px;
  margin-bottom: 20px;
}

.DetailsOwnerButton-responsive.effective,
.AssignedButton-responsive.effective {
  background-color: var(--Primary_white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 39px;
}

.DetailsOwnerButton.effective,
.AssignedButton.effective {
  background-color: var(--Primary_white);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.editTag {
  width: 38px;
  height: 30px;
  position: absolute;
  /* cursor: pointer; */
  background-color: var(--Blue_7);
  top: 0;
  right: 0;
  border-radius: 0px 22px;
  /* opacity: 1; */
}

.disableedittag {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.edit-icon {
  width: 15px;
  height: 15px;
  position: relative;
  top: 8px;
  left: 12px;
}

.NotagsAssigned {
  color: var(--Primary_grey);
  font: normal normal 400 14px/22px Open Sans;
}

.ownerdetail-border-div {
  height: 70%;
  /* margin: 13px 0 0 0; */
  border-right: 1px solid #1d3a6d;
}

.ownerdetail-border-div-bottom {
  width: 288px;
  border-bottom: 1px solid #1d3a6d;
}

@media only screen and (max-width: 531px) {
  .ownerdetail-border-div-bottom-responsive {
    width: 100%;
    border-bottom: 1px solid #1d3a6d;
  }
}

@media only screen and (min-width: 531px) {
  .ownerdetail-border-div-bottom-responsive {
    width: 100%;
    border-bottom: 1px solid #1d3a6d;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 800px) {
  .major-div-responsive {
    margin-top: 65px;
  }
}

.OAS-owner_acc_Calendar_main_div {
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  right: 20px;
}

.OAS-owner_acc_date_heading_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-owner_acc_date_heading_left {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.OAS-owner_acc_viewall_calendar_icon {
  height: 17px;
  align-items: center;
  justify-content: center;
  width: 20px !important;
}

.OAS-owner_acc_date_select_div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 35px;
  width: auto;
}

.OAS-owner_acc_date_heading_right {
  text-align: center;
  font: normal normal normal 12px/17px Open Sans;
  letter-spacing: 0px;
  color: var(--Primary_white);
  opacity: 1;
  width: 100px;
  margin: 0 auto;
}

.supp-info-div {
  font-size: 12px;
  color: var(--Primary_grey);
  padding-bottom: 10px;
  width: 100%;
}

.statistics-date-note{
  color: var(--Primary_white);
  font-size: 12px;
  font-weight: 400;
  margin: 42px 0px 20px 0px;
}
.statistics-second-card-summary{
  margin-top: 10px;
}