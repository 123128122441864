.RejectAllTxn_pop_div {
  width: 96vw;
  height: 92vh;
  top: 4%;
  left: 2%;
  position: fixed;
  padding-bottom: 52px;
}



.reject-txn-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* width: 95%; */
  max-width: 794px;
  height: 612px;
  background: #153164 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.reject-txn-popup-title {
  display: flex;
  flex-direction: column;
}

.reject-txn-popup-title-head {
  width: 300px;
  height: 27px;
  text-align: left;
  font: normal normal 600 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #ff7f33;
  opacity: 1;
  margin-left: 30px;
}

.reject-txn-popup-title-main-div {
  width: 794px;
  display: flex;
}

/* .reject-txn-popup-title-subhead {
  width: 700px;
  height: 22px;
  text-align: left;
  font: normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #DADADA;
  opacity: 1;
  margin-left: 30px;
  margin-top: 10px;
} */

.reject-txn-popup-btn-container {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  margin-right: 30px;
  gap: 30px;
  margin-bottom: 10px;
}

.reject-txn-popup-cancleBtn {
  width: 50px;
  height: 22px;
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 16px/14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
  opacity: 1;
}

.reject-txn-popup-confirmBtn {
  width: 100px;
  height: 42px;
  background-color: #69b76f;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  font: normal normal normal 16px/14px Open Sans;
  letter-spacing: 0px;
  color: #f9f9f9;
}

#infinityScrollContainerAccountTransaction {
  width: 100%;
  overflow-y: auto;
  position: relative;
  height: 65%;
  /* margin-top: 50px; */
}

.reject-txn-expentraLoaderclass {
  position: relative;
  background-color: #ff5860 !important;
  width: 100px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* UI Properties */
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.reject-txn-expentraLoaderclass img {
  width: 40px;
  height: 40px;
}
.reject-txn-popup-title-subhead {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
  font: normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #F9F9F9;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reject-decline-reason {
  display: inline-block;
  word-wrap: break-word;
  width: auto;
  max-width: 720px;
}

@media screen and (max-width: 768px) {
  .reject-txn-popup-title-subhead {
    font-size: 14px;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width: 480px) and (max-width: 718px) {
  .reject-txn-popup {
    max-width: 564px !important;
  }
}
@media screen and (min-width: 720px) and (max-width: 800px) {
  .reject-txn-popup {
    max-width: 717px !important;
  }
  .bene_reject_close_div {
    margin-left: 325px !important;
  }
}
@media screen and (min-width: 400px) and (max-width: 470px) {
  .reject-txn-popup {
    max-width: 400px !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .reject-txn-popup {
    max-width: 350px !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 500px) {
  .reject-txn-popup-title-head {
    width: 251px !important;
    height: 50px !important;
  }
  .bene_reject_close_div {
    margin-left: 14px !important;
  }
}
@media screen and (min-width: 500px) and (max-width: 720px) {
  .bene_reject_close_div {
    margin-left: 180px !important;
  }
}
